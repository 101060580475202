<template>
  <div class="home-wrapper">
    <div class="home-cont" :style="contStyle">
      <header :class="['header', currentId > 1 ? 'header-change' : '']">
        <div class="logo">
          <img v-show="currentId === 1" src="../../assets/logo-w.png" />
          <img v-show="currentId > 1" src="../../assets/logo.png" />
        </div>
        <div class="nav-cont">
          <div class="nav navActive">首 页</div>
          <!-- <div class="nav" @click="jumpTo('Business')">主要业务</div> -->
          <div class="nav" @click="jumpTo('About')">关于我们</div>
          <div class="nav" @click="jumpTo('Join')">加入我们</div>
        </div>
      </header>
      <div class="bannerContent">
        <div class="banner page">
          <img src="../../assets/new_title.png" class="bannerImage" />
        </div>

        <div class="business page">
          <div class="b-title">主要业务</div>
          <div class="b-title2">
            华越致力于为客户提供领先，安全，以扩展的信息化定制解决方案以及相关软件产品、平台服务
          </div>
          <div class="business-cont">
            <div class="card" @click="jumpMessage('Ipd')">
              <div class="cont">
                <img src="../../assets/development.png" />
              </div>
              <div class="info">
                <div class="tit">研发体系咨询</div>
                <div class="text">
                  华越的专家顾问组，可以为制造企业提供专业的咨询服务。
                </div>
              </div>
            </div>
            <div class="card" @click="jumpMessage('Plm')">
              <div class="cont">
                <img src="../../assets/plm.png" />
              </div>
              <div class="info">
                <div class="tit">PLM规划与实施</div>
                <div class="text">
                  主要是面向制造业的产品研发领域，支持企业内部研发数据的创建、管理、分发和应用的一系列应用解决方案。
                </div>
              </div>
            </div>
            <div class="card" @click="jumpMessage('Iot')">
              <div class="cont">
                <img src="../../assets/rd.png" />
              </div>
              <div class="info">
                <div class="tit">物联网</div>
                <div class="text">
                  把所有物品通过网络连接起来，实现任何物体、任何人、任何时间、任何地点（4A）的智能化识别、信息交换与管理。
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="partner page">
          <div class="partner-cont">
            <div class="title">合作伙伴</div>
            <div class="card">
              <div class="img"><img src="../../assets/vivo.png" /></div>
            </div>
            <div class="card">
              <div class="img"><img src="../../assets/sq.png" /></div>
            </div>
            <div class="card">
              <div class="img"><img src="../../assets/oppo.png" /></div>
            </div>
            <div class="card">
              <div class="img"><img src="../../assets/zh.png" /></div>
            </div>
            <div class="card">
              <div class="img"><img src="../../assets/fast.png" /></div>
            </div>
            <div class="card">
              <div class="img"><img src="../../assets/chinasmoke.png" /></div>
            </div>
            <div class="card">
              <div class="img"><img src="../../assets/zt.png" /></div>
            </div>
            <div class="card">
              <div class="img"><img src="../../assets/xd.png" /></div>
            </div>
          </div>
          <div class="footer">
            <div class="footer-cont">
              <div class="box1">
                <div>
                  <svg
                    version="1.0"
                    id="图层_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 16 16"
                    enable-background="new 0 0 16 16"
                    xml:space="preserve"
                  >
                    <g>
                      <path
                        fill="#B8BECC"
                        d="M14,3H2C1.45,3,1,3.45,1,4v8c0,0.55,0.45,1,1,1h12c0.55,0,1-0.45,1-1V4C15,3.45,14.55,3,14,3z M14,12H2V4
                    h12V12z"
                      />
                      <path
                        fill="#B8BECC"
                        d="M6.41,8.5h6c0.28,0,0.5-0.22,0.5-0.5s-0.22-0.5-0.5-0.5h-6c-0.28,0-0.5,0.22-0.5,0.5S6.13,8.5,6.41,8.5z"
                      />
                      <path
                        fill="#B8BECC"
                        d="M12.48,9.79h-6c-0.28,0-0.5,0.22-0.5,0.5s0.22,0.5,0.5,0.5h6c0.28,0,0.5-0.22,0.5-0.5
                    S12.75,9.79,12.48,9.79z"
                      />
                      <circle fill="#B8BECC" cx="4.33" cy="6.34" r="1" />
                    </g>
                  </svg>
                  西安华越信息技术有限公司
                </div>
                <div>
                  <svg
                    version="1.0"
                    id="图层_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 16 16"
                    enable-background="new 0 0 16 16"
                    xml:space="preserve"
                  >
                    <g>
                      <path
                        fill="#B8BECC"
                        d="M8,1.5c-2.99,0-5.43,2.43-5.43,5.43c0,1.97,2.18,4.86,4,6.93C6.93,14.26,7.45,14.5,8,14.5
                      s1.07-0.24,1.43-0.64c1.83-2.07,4-4.96,4-6.93C13.43,3.93,10.99,1.5,8,1.5z M8.68,13.2c-0.35,0.39-1,0.39-1.35,0
                      C4.94,10.49,3.57,8.2,3.57,6.93C3.57,4.49,5.56,2.5,8,2.5s4.43,1.99,4.43,4.43C12.43,8.2,11.06,10.49,8.68,13.2z"
                      />
                      <path
                        fill="#B8BECC"
                        d="M8,4.5C6.62,4.5,5.5,5.62,5.5,7S6.62,9.5,8,9.5s2.5-1.12,2.5-2.5S9.38,4.5,8,4.5z M8,8.5
                      C7.17,8.5,6.5,7.83,6.5,7S7.17,5.5,8,5.5S9.5,6.17,9.5,7S8.83,8.5,8,8.5z"
                      />
                    </g>
                  </svg>
                  西安市高新高新区唐延南路与锦业路交汇处逸翠园i都会4号楼1单元2504
                </div>
                <div>
                  <svg
                    version="1.0"
                    id="图层_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 16 16"
                    enable-background="new 0 0 16 16"
                    xml:space="preserve"
                  >
                    <path
                      fill="#B8BECC"
                      d="M13,9.56c-1.25-1.19-2.4-1.43-3.5-0.75C9.44,8.85,9.37,8.9,9.29,8.96L9.05,9.14C9.03,9.13,9,9.11,8.98,9.1
                  c-0.43-0.33-0.82-0.7-1.16-1.12c-0.3-0.38-0.54-0.8-0.68-1.2L7.13,6.73l0.02-0.01c1.57-1.37,0.86-2.86,0.2-3.85
                  C6.77,2,6.07,1.54,5.27,1.5h0c-0.82-0.04-1.41,0.32-2.1,0.9l-0.3,0.25C2.38,3.04,2.08,3.64,2.01,4.38C1.85,6.1,2.9,8.59,4.7,10.72
                  c1.79,2.12,4.26,3.78,6.14,3.78c0.57,0,1.09-0.15,1.51-0.5l0.5-0.39c0.6-0.49,0.91-0.91,1.06-1.45C14.1,11.55,14.09,10.59,13,9.56z
                  M12.96,11.89c-0.1,0.33-0.3,0.6-0.73,0.95l-0.5,0.39c-1.14,0.93-4.07-0.55-6.27-3.15c-1.61-1.9-2.59-4.16-2.45-5.6
                  C3.05,4,3.22,3.65,3.5,3.42l0.31-0.26C4.45,2.62,4.79,2.5,5.15,2.5c0.02,0,0.05,0,0.07,0c0.48,0.02,0.9,0.32,1.3,0.92
                  c0.89,1.33,0.67,1.94-0.01,2.53L6.04,6.36l0.04,0.28c0.02,0.11,0.04,0.22,0.08,0.34L6.2,7.09C6.37,7.6,6.68,8.14,7.05,8.61
                  c0.39,0.48,0.84,0.91,1.32,1.28c0.1,0.08,0.21,0.15,0.34,0.24l0.37,0.22l0.81-0.59c0.05-0.04,0.1-0.07,0.15-0.1
                  c0.51-0.32,1.14-0.46,2.28,0.63C12.9,10.84,13.11,11.36,12.96,11.89z"
                    />
                  </svg>
                  电话：029-81149376
                </div>
                <div>
                  <svg
                    version="1.0"
                    id="图层_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 16 16"
                    enable-background="new 0 0 16 16"
                    xml:space="preserve"
                  >
                    <path
                      fill="#B8BECC"
                      d="M13.5,3h-11c-0.55,0-1,0.45-1,1v8c0,0.55,0.45,1,1,1h11c0.55,0,1-0.45,1-1V4C14.5,3.45,14.05,3,13.5,3z
                  M12.72,4l-4.4,4.07C8.14,8.23,7.87,8.23,7.69,8.09L3.28,4H12.72z M2.5,12V4.64l4.53,4.19c0.28,0.24,0.62,0.35,0.96,0.35
                  c0.35,0,0.7-0.12,0.99-0.37l4.51-4.18V12H2.5z"
                    />
                  </svg>
                  hr@xa-huayue.com
                </div>
              </div>
            </div>
            <div class="line"></div>
            <div class="footer-cont">
              <div class="box2">
                Copyright ©2014 - 2021 西安华越信息技术有限公司
                <a href="https://beian.miit.gov.cn/" target="_blank"
                  >陕ICP备14005811号</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},

  data() {
    return {
      headBColor: false,
      currentId: 1,
      direction: "btt",
    };
  },

  computed: {
    contStyle() {
      return {
        top: `-${this.currentId - 1}00%`,
      };
    },
  },

  watch: {
    currentId(val) {
      sessionStorage.setItem("currentId", val + "");
    },
  },

  methods: {
    jumpTo(routerName) {
      this.$router.push({ name: routerName });
    },
    jumpMessage(val) {
      this.$router.push({ name: val });
    },
    //函数防抖
    debounce(func, wait) {
      let timeout;
      return function () {
        let context = this;
        let args = arguments;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(context, args);
        }, wait);
      };
    },
  },

  created() {},

  mounted() {},

  beforeDestroy() {
    window.removeEventListener("mousewheel", this.debounce());
  },
};
</script> 

<style lang="scss" scoped>
.home-wrapper {
  width: 100%;
  min-width: 1200px;
  height: 100%;
  touch-action: none;
  overflow: hidden;
  position: relative;
  .home-cont {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    transition: top 0.7s ease-out;
    .page {
      width: 100%;
      overflow: hidden;
      padding-top: 60px;
    }
    .header {
      display: flex;
      justify-content: space-between;
      width: 100vw;
      height: 60px;
      padding-bottom: 10px;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999999;
      padding: 0 35px 0 0px;
      background: rgba($color: #1c223d, $alpha: 0.8);
      box-sizing: border-box;
      box-shadow: 0px 0px 5px 5px rgba($color: #1f2642, $alpha: 0.1);
      transition: all 0.5s;
      .logo {
        width: 200px;
        > img {
          width: 100%;
        }
      }
      .nav-cont {
        width: 400px;
        height: 60px;
        .nav {
          float: left;
          height: 60px;
          line-height: 60px;
          color: #ccc;
          padding: 0 15px;
          cursor: pointer;
          font-size: 18px;
        }
        .nav:hover {
          color: #597ef7;
          border-bottom: 2px solid #597ef7;
        }
        .navActive {
          color: #597ef7;
          border-bottom: 2px solid #597ef7;
        }
      }
    }
    .header-change {
      background: #fff;
      .nav {
        color: #707070 !important;
      }
      .nav:hover {
        color: #597ef7 !important;
        border-bottom: 2px solid #597ef7;
      }
      .navActive {
        color: #597ef7 !important;
        border-bottom: 2px solid #597ef7;
      }
    }
    .bannerContent {
      width: 100%;
      height: 100%;
      overflow-y: auto;
    }
    .banner {
      width: 100%;
      padding: 0;
      .bannerImage {
        display: block;
        width: 100%;
        margin-top: 60px;
      }
    }
    .business {
      width: 1200px;
      margin: auto;
      .b-title {
        font-size: 32px;
        font-weight: bold;
        padding-bottom: 20px;
      }
      .b-title2 {
        font-size: 20px;
      }
      .business-cont {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .card {
          cursor: pointer;
          width: 360px;
          height: 470px;
          background: #fff;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          margin: 25px 0;
          .cont {
            width: 100%;
            height: 340px;
            background: #f2f5ff;
            overflow: hidden;
            > img {
              width: 100%;
            }
          }
          .info {
            height: 120px;
            padding: 20px;
            text-align: left;
            .tit {
              color: #5a7ef7;
              font-size: 20px;
              font-weight: bold;
              padding-bottom: 10px;
            }
            .text {
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
        .card:hover {
          box-shadow: 0px 2px 10px 0px rgba(26, 23, 255, 0.3);
        }
      }
    }
    .partner {
      width: 100;
      background: #f9fbff;
      padding-top: 120px;
      .partner-cont {
        height: calc(100vh - 77px - 140px);
        width: 1200px;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .title {
          width: 100%;
          font-size: 32px;
          font-weight: bold;
        }
        .card {
          width: 268px;
          height: 192px;
          background: #fff;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          margin: 20px 0 20px;
          overflow: hidden;
          position: relative;
          .img {
            width: 268px;
            height: 192px;
            padding: 26px 34px;
            > img {
              width: 100%;
            }
          }
        }
      }
      .footer {
        background: #26282e;
        color: #a4a5a8;
        position: relative;
        overflow: hidden;
        height: 77px;
        svg {
          width: 16px;
          height: 16px;
          vertical-align: middle;
          margin-top: -2px;
        }
        .line {
          width: calc(100% - 120px);
          height: 1px;
          background: #494c4f;
          margin: 0 auto;
        }
        .footer-cont {
          width: 1200px;
          margin: auto;
          .box1 {
            padding: 10px 0;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            line-height: 16px;
          }
          .box2 {
            padding: 15px 0;
            font-size: 12px;
            line-height: 16px;
            a {
              margin-left: 10px;
              color: #a4a5a8;
              text-decoration: none;
            }
          }
          background: #26282e;
        }
      }
    }
  }
}
</style>