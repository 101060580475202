import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/index.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'index',
    redirect: '/Home'
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/Business',
    name: 'Business',
    component: () => import('../views/Business/index.vue')
  },
  {
    path: '/About',
    name: 'About',
    component: () => import('../views/About/index.vue')
  },
  {
    path: '/Join',
    name: 'Join',
    component: () => import('../views/Join/index.vue')
  },
  {
    path: '/Plm',
    name: 'Plm',
    component: () => import('../views/Plm/index.vue')
  },

  {
    path: '/Ipd',
    name: 'Ipd',
    component: () => import('../views/Ipd/index.vue')
  },

  {
    path: '/Iot',
    name: 'Iot',
    component: () => import('../views/Iot/index.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router